<template>
  <HeadMain
    meta-title="MetaWin: Win huge crypto prizes and digital collectibles with complete transparency."
    meta-description="Experience the thrill of winning with the benefits of Web3. Security, privacy, transparency. Welcome to the future of winning. Welcome to MetaWin."
    og-title="MetaWin: Win huge crypto prizes and digital collectibles with complete transparency."
    og-description="Experience the thrill of winning with the benefits of Web3. Security, privacy, transparency. Welcome to the future of winning. Welcome to MetaWin."
    remove-title-appendix
  />

  <div class="lg:px-2 xl:px-5 mx-auto w-full max-w-[1800px]">
    <CarouselPromoBanners v-if="!isUserLogged" page="homepage-html" class="lg:mt-4"/>
  </div>

  <!-- <UserRegisterBanner v-if="!isUserLogged"/> -->

  <ClientOnly>
    <LazyModalRaffleMain
      v-if="showRaffleEntryModal"
      :raffle-data="raffleSelected"
      :entry-response="entryResponse"
      :entry-package-selected="entryPackageSelected"
      :exchange-rate-eth-usd="exchangeRateEthUsd"
      modal-width-mode="xl"
      @on-entry-selected="onEntrySelected"
    />
    <LazyModalWinRaffle v-if="showRaffleWinModal" :raffle-data="raffleWin"/>
    <LazyModalCompetitionEnterPassword v-if="showEnterPasswordModal"/>
    <LazyModalWalletConfirmMain v-if="showWalletConfirmModal"/>
    <LazyModalConfirmMain v-if="showConfirmModal" @confirm="onEntrySelected"/>

    <main class="px-2 xl:px-5 pb-5 relative z-0 w-full mx-auto max-w-[1800px] min-h-screencalc-minus-nav">
      <NavigationMobileQuickTabs v-if="isUserLogged"/>
      <ListWinsMain/>

      <OgPoints/>

      <CarouselPromoBanners v-if="isUserLogged" page="homepage-html" class="mt-1"/>

      <Spinner v-if="isLoadingData && sweepstakesDisplay.length === 0"/>

      <LeaderboardCoreInstant
        v-if="isUserLogged"
        leaderboard-schedule-name="MonthlyWagering"
        show-title
        hide-close-button
        placement="page"
        :count="5"
        class="lg:relative bg-slate-900 pb-2 lg:pt-0 lg:top-0 rounded-lg lg:rounded-xl mb-2 overflow-hidden"
      >
        <template #header>
          <ButtonLink
            class="ml-2 relative -top-[2px]"
            to="/games"
            size="2xs"
          >
          Enter Now
          </ButtonLink>
        </template>
      </LeaderboardCoreInstant>
      <div
        v-if="sweepstakesDisplay.length === 0 && !isLoadingData"
        class="text-center w-full px-3 pt-6 pb-14"
      >
        <h2 class="lg:text-xl py-3 text-slate-100 animate-fade-in mb-8">
          No competitions available at the moment. Try again later.
        </h2>
      </div>
      <div v-if="!isUserLogged" class="action-blocks-container flex flex-col gap-4 sm:grid sm:grid-rows-1 sm:grid-cols-2">
        <CmsActionBlock
          v-for="(item, i) in cmsActionBlocks"
          :key="i"
          :icon="item.icon"
          :title="item.title"
          :image="item.image"
          :message-heading="item.messageHeading"
          :message="item.message"
          :action-label="item.actionLabel"
        />
      </div>
      <PaymentsBanner v-if="!isUserLogged"/>

      <div v-if="sweepstakes !== null && isUserLogged" class="relative">
        <ListSweepstakeCategorised
          :sweepstakes-display="sweepstakesDisplay"
          :instant-win-games-promoted="instantWinGamesPromotedDisplay"
          :live-games-promoted="liveGamesPromotedDisplay"
          :exchange-rate-eth-usd="exchangeRateEthUsd"
          :display-categories-data="displayCategoriesData"
          @card-action="openRaffleModal"
        />
      </div>

      <LeaderboardTabbed v-if="isUserLogged" class="pt-4"/>
      <LeaderboardLoggedOut v-if="!isUserLogged"/>
    </main>

    <LazyFooterMain/>
  </ClientOnly>
</template>

<script>
import { mapActions, mapWritableState, mapState } from 'pinia';
import { useTimeoutFn } from '@vueuse/core';
import { useSweepstakeStore } from '@/store/sweepstake';
import { useSweepstakeDataStore } from '@/store/sweepstakeData';
import { useCryptoStore } from '@/store/crypto';
import { useCompetitionStore } from '@/store/competitions';
import { useUiStore } from '@/store/ui';
import { useUserStore } from '@/store/user';
import { useAuthStore } from '@/store/auth';
import { useWebsocketStore } from '@/store/websocket';
import { useCasinoStore } from '@/store/casino';
import { useDeviceStore } from '@/store/device';
import { useBankingStore } from '@/store/banking';

export default {
  name: 'CompetitionsHomepage',
  inject: ['mq',],
  setup() {
    definePageMeta({
      chatDefaultState: { mobile: 'hidden', desktop: 'hidden', },
    });
    const websocketStore = useWebsocketStore();
    return { websocketStore, };
  },
  data() {
    return {
      isLoadingData: true,
      entryPackageSelected: null,
      entryResponse: null,
      raffleWin: null,
      raffleSelectedId: null,
      pageUnmounted: false,
      displayCategoriesData: null,
      tabActive: 'reelrace',
      cmsActionBlocks: [
        {
          icon: 'fire',
          title: 'Crypto Competitions',
          image: 'crypto',
          messageHeading: 'Win Crypto & Blue-Chip NFT Prizes',
          message: 'At MetaWin you can win huge crypto prizes. \'Beznik\' won a massive $1 Million USDC. Will you be our next big winner?',
          actionLabel: 'Enter For Free',
        },
        {
          icon: 'cherry',
          title: '$1,000,000 Monthly Reel Race',
          image: 'metarace',
          messageHeading: '$1,000,000 Monthly Reel Race',
          message: 'Join our Monthly Wagering Leaderboard and compete for your share of a massive $1,000,000 prize pool!',
          actionLabel: 'Play Now',
        },
      ],
    };
  },
  computed: {
    ...mapWritableState(useSweepstakeStore, ['sweepstakes', 'pendingQueue', 'activityUpdatePayload', 'activityUpdateCount', 'pollerSweepstake',]),
    ...mapWritableState(useUiStore, ['showRaffleEntryModal', 'showRaffleWinModal', 'showWalletConfirmModal', 'showConfirmModal', 'chatState',]),
    ...mapState(useCompetitionStore, ['showEnterPasswordModal',]),
    ...mapState(useCryptoStore, ['exchangeRateEthUsd',]),
    ...mapState(useUserStore, ['userData',]),
    ...mapState(useAuthStore, ['isUserLogged',]),
    ...mapState(useSweepstakeDataStore, ['live',]),
    ...mapState(useCasinoStore, ['instantWinGamesPromoted', 'liveGamesPromoted',]),
    ...mapState(useDeviceStore, ['isMetaMaskMobile',]),
    ...mapState(useBankingStore, ['currentCurrency',]),
    sweepstakesFiltered() {
      // Merge static data when dynamic data is available
      // const staticLiveSweepstakes = this.sweepstakes.length ? this.live : []
      let sweepstakes = this.sweepstakes;
      if (!sweepstakes.length) { return []; }

      // Filter out items that failed - avoid negative display for users
      sweepstakes = sweepstakes.filter(e => [2, 3, 4, 5, 6, 8,].includes(e?.state));

      sweepstakes.forEach((item) => {
        // genereate entryFrom value
        if (item.entryPackages.length) {
          const avPackages = item.entryPackages.filter(entry => entry.available).sort((a, b) => a.enterFrom - b.enterFrom);
          avPackages.length ? item.enterFrom = Number(avPackages[0].price) : item.enterFrom = null;
        } else {
          item.enterFrom = null;
        }
        // find entry with the highest state
        if (item.entries) {
          item.userEnterState = 'entered';
        }
        // overwrite status if there's entry in pending queue
        if (this.pendingQueue.length) {
          this.pendingQueue.forEach((pendingEntry) => {
            if (pendingEntry.sweepstakeId === item.id) {
              item.userEnterState = 'pending';
            }
          });
        } else if (!this.pendingQueue.length && item.userEnterState !== 'entered') {
          item.userEnterState = null;
        }
      });

      // sort using lodash with closeTime and secondary with enterFrom
      sweepstakes = _SortBy(sweepstakes, ['closeTime', 'enterFrom',]);

      // sorting overrides for featured and placeholder sweepstakes
      sweepstakes = sweepstakes
      ?.sort((a, b) => (a.featured === b.featured ? 0 : a.featured ? -1 : 1))
      ?.sort((a, b) => (a.metadata?.placeholder === b.metadata?.placeholder ? 0 : a.metadata?.placeholder ? -1 : 1));

      return sweepstakes;
    },
    sweepstakesScheduled() {
      let sweepstakes = this.sweepstakesFiltered;
      sweepstakes = sweepstakes?.sort((a, b) => (a.openTime > b.openTime ? 1 : -1));
      sweepstakes = sweepstakes.filter(e => [2,].includes(e?.state) && !e?.metadata?.forceSortWithCloseTime);
      return sweepstakes;
    },
    sweepstakesOpen() {
      let sweepstakes = this.sweepstakesFiltered;
      sweepstakes = sweepstakes.filter(e => [3, 4, 5, 6, 8,].includes(e?.state) || ([2,].includes(e?.state) && e.presentationType === 'Competition') || e?.metadata?.forceSortWithCloseTime);
      return sweepstakes;
    },
    sweepstakesDisplay() {
      const sweepstakes = this.sweepstakesOpen.concat(this.sweepstakesScheduled);
      return sweepstakes;
    },
    sweepstakesDisplayLength() {
      return this.sweepstakesDisplay.length;
    },
    raffleSelected() {
      const raffle = this.sweepstakesDisplay.find(o => o.id === this.raffleSelectedId) || null;
      return raffle || null;
    },
    instantWinGamesPromotedDisplay() {
      return this.instantWinGamesPromoted;
    },
    liveGamesPromotedDisplay() {
      let games = this.liveGamesPromoted;
      games = games.filter(e => e?.isHidden !== true);
      return games;
    },
  },
  watch: {
    showRaffleEntryModal(val) {
      return val === false ? this.resetSelected() : null;
    },
    showRaffleWinModal(val) {
      return val === false ? (this.raffleWin = null) : null;
    },
    isUserLogged() {
      this.init();
    },
    raffleSelected(newVal) {
      if (!newVal) {
        this.showRaffleEntryModal = false;
        this.raffleSelectedId = null;
        this.entryPackageSelected = null;
      }
    },
  },
  mounted() {
    this.init();
    this.subscribe('Activity:Sweepstakes');
    this.websocketStore.$onAction(({ name, args, }) => {
      if (name === 'handleMessage') {
        if (args[0].type === 'Notification:Added') {
          useMessageHandler({ name, args, }, this.winModal, 'Notification:Added', 'SweepstakeWin');
        }
        if (args[0].type === 'Activity') {
          useMessageHandler({ name, args, }, this.activityUpdate, 'Activity', 'SweepstakeEntered');
        }
      }
    });
  },
  beforeUnmount() {
    this.pageUnmounted = true;
    this.unsubscribe('Activity:Sweepstakes');
    clearInterval(this.pollerSweepstake);
    this.pollerSweepstake = null;
    this.showRaffleEntryModal = false;
    this.resetCasinoStore();
  },
  methods: {
    ...mapActions(useSweepstakeStore, ['getSweepstakes', 'enterClientRaffle', 'enterServerRaffle', 'getDisplayCategories', 'resetSweepstakesData',]),
    ...mapActions(useUiStore, ['openConnectModal',]),
    ...mapActions(useWebsocketStore, ['subscribe', 'unsubscribe',]),
    ...mapActions(useCasinoStore, ['loadInstantWinGamesPromoted', 'loadLiveGamesPromoted', 'startDGA', 'resetCasinoStore',]),
    async init() {
      useTimeoutFn(() => {
        if (this.mq.xlPlus) { this.chatState = !this.isUserLogged ? 'hidden' : 'fullscreen'; }
      }, 1000);
      this.resetSweepstakesData();
      this.displayCategoriesData = await this.getDisplayCategories();

      if (this.$config.public.CASINO_CATEGORY_HOMEPAGE_ACTIVE && this.displayCategoriesData?.find(category => category.categoryFilter === 'instant-win-games')) {
        this.loadInstantWinGamesPromoted();
      }
      if (this.displayCategoriesData?.find(category => category.categoryFilter === 'live-games')) {
        this.resetCasinoStore();
        this.loadLiveCasinoSection();
      }
      await this.getSweepstakes();

      this.isLoadingData = false;

      if (!this.pageUnmounted) {
        this.startPolling();
      }
    },
    startPolling() {
      // Call sweepstakes and history API with interval
      if (!this.pollerSweepstake) {
        this.pollerSweepstake = setInterval(async() => {
          await this.getSweepstakes();
        }, 5000);
      }
    },
    openRaffleModal(raffle) {
      if (!this.isUserLogged) {
        this.openConnectModal({
          message: 'Experience winning in Web3 by connecting your wallet',
        });
        return;
      }
      this.entryResponse = null;
      this.raffleSelectedId = raffle.id;
      if (!this.raffleSelectedId) { return; }
      this.showRaffleEntryModal = true;
      // this.entryResponse = 'error' // Uncomment to test ModalRaffleContentEntered, try 'error' or 'success'
    },
    async onEntrySelected(entryPackage) {
      this.entryResponse = 'loading';
      this.entryPackageSelected = entryPackage;
      const entryType = entryPackage?.entryAction?.type || null;
      let result = null;
      if (entryType === 'Server') {
        result = await this.enterServerRaffle(this.raffleSelected.id, entryPackage, this.currentCurrency);
      } else if (entryType === 'Client') {
        result = await this.enterClientRaffle(this.raffleSelected, entryPackage);
      }
      if (result && (!this.raffleSelected || (Number(this.raffleSelected.id) !== result?.sweepstake?.id && Number(this.raffleSelected.id) !== result?.sweepstakeId))) { return; }

      // this.entryResponse feeds into modal/raffle/Main.vue
      if (result?.entries?.length || result?.res) {
        this.entryResponse = 'success';
      } else if (result?.res === false) {
        this.entryResponse = 'error';
        this.entryPackageSelected = null;
      } else {
        this.entryResponse = null;
        this.entryPackageSelected = null;
      }
      this.getSweepstakes();
    },
    resetSelected() {
      this.raffleSelectedId = null;
      this.entryPackageSelected = null;
      this.entryResponse = null;
    },
    winModal(payload) {
      if (payload?.data?.prizes?.find(prize => prize.reward)) { return; }
      this.showRaffleWinModal = false;
      this.raffleWin = payload;
      if (payload?.data?.presentationType === 'Lootbox') {
        setTimeout(() => {
          this.showRaffleWinModal = true;
        }, 400);
      } else {
        this.showRaffleWinModal = true;
      }
    },
    activityUpdate(payload) {
      if (payload?.subCategory !== 'Competition') { return; }
      this.activityUpdatePayload = payload;
      this.activityUpdateCount++;
    },
    async loadLiveCasinoSection() {
      await this.loadLiveGamesPromoted(); // Load promoted live games from Sanity CMS
      if (this.liveGamesPromoted.length > 0) { this.startDGA(this.liveGamesPromoted); }
    },
  },
};
</script>
